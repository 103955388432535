// https://www.epicwinphp.com/
// export const GAME_URL = 'https://epwin8.com';

// https://www.epicwinphp.com/c11/
// export const GAME_URL = 'https://epwin11.com';

// https://www.epicwinphp.com/c12/
// export const GAME_URL = 'https://epwin12.com';

// https://www.epicwinphp.com/c13/
// export const GAME_URL = 'https://epwin13.com';

// https://www.epicwinphp.com/c14/
export const GAME_URL = 'https://epwin14.com';

// https://www.epicwinphp.com/c15/
// export const GAME_URL = 'https://epwin15.com';

export const APP_NAME = 'EpicWin Philippines';

export const LTD_NAME = 'EpicWin';
